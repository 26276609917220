import { graphql, useStaticQuery } from "gatsby";

const useProyectos = () => {
    const resultado = useStaticQuery(
        graphql`
        query{
            allStrapiPaginas(filter: {nombre: {eq: "Proyectos"}}){
                nodes{
                    id
                    nombre
                    contenido
                    imagen{
                        sharp: childImageSharp {
                            fluid(maxWidth: 1500 duotone: {
                                highlight: "#222222", shadow: "#192550", opacity: 30
                            }){
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                }
            }
            
        `
    );
    
    return resultado.allStrapiPaginas.nodes.map( proyecto => ({
        nombre: proyecto.nombre,
        contenido: proyecto.contenido,
        imagen: proyecto.imagen
    }));
}
export default useProyectos;