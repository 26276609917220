import React, {useEffect} from 'react';
import { css } from "@emotion/react";
import styled from '@emotion/styled';
import BackgroundImage from 'gatsby-background-image';
import ReactMarkdown from 'react-markdown';

import Layout from '../components/layout';
import useProyectos from '../hooks/useProyectos';
import heroCSS from '../css/hero.module.css';

const ImagenBackground = styled(BackgroundImage)`height: 600px;`;

const Proyectos = () => {

    const proyectos = useProyectos();
    const {nombre, contenido, imagen} =proyectos[0]; 

    // conexxion con comentarios en GraphComment
    useEffect(() => {
        window.gc_params = {
            graphcomment_id: 'electronatural',
            fixed_header_height: 0,
        };

        (function() {
            var gc = document.createElement('script'); gc.type = 'text/javascript'; gc.async = true;
            gc.src = 'https://graphcomment.com/js/integration.js?' + Date.now();
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(gc);
        })();
    }, []);
    
    return ( 
        <Layout>
            <ImagenBackground
                tag="section"
                fluid={imagen.sharp.fluid}
                fadeIn="soft"
            >
                <div className={heroCSS.imagenbg}>
                    <h1 className={heroCSS.titulo}>Ingresa a la APP de Proyectos</h1>
                    <h2 className={heroCSS.subtitulo}>Crea tus popios proyectos</h2>
                    <button
                        className={heroCSS.botonTransparente}
                    ><a 
                        href="https://electronatural-proyectos.netlify.app/"
                        rel="noreferrer"
                        target="_blank"
                    >Ingresar a la APP</a></button>
                </div>
            </ImagenBackground>
            <main>
                <div
                    css={css`
                        max-width: 800px;
                        margin: 0 auto;
                    `}
                >
                    <h1>{nombre}</h1>
                    <ReactMarkdown css={css`text-align: justify;`}
                    >{contenido}</ReactMarkdown>
                </div>
                <div id="graphcomment"></div>
            </main>
            
        </Layout> 
        
    );
}
 
export default Proyectos;